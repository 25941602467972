/* Оригинальные стили страницы */
body {
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f5f5f5;
  color: #333;
}

.container {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

h1 {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333333;
}

h3 {
  margin: 15px 0;
  font-size: 18px;
  color: #444444;
}

p {
  margin: 10px 0;
  font-size: 16px;
  color: #666666;
}

input[type="text"],
select,
button,
input[type="submit"] {
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.downloadButton {
  background-color: #007bff;
  color: #ffffff;
}

.downloadButton:hover {
  background-color: #025bb9;
  transition: background-color 0.3s;
}

input[type="submit"] {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

input[type="submit"]:hover {
  background-color: #0056b3;
}

.linkForDownload {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  color: #333;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;
  word-wrap: break-word;
}

#filenameOS {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hidden {
  display: none;
}

.toggle-button {
  margin-bottom: 20px;
}

.toggle-button button {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button .active {
  background-color: #007bff;
  color: #ffffff;
}

.toggle-button button:not(.active) {
  background-color: #cccccc;
  color: #333333;
}

#loadingGif {
  width: 100px;
  height: 100px;
  display: inline-block;
  vertical-align: middle;
}

/* New styles for Fastboot buttons */
button {
  background-color: #007bff;
  color: #ffffff;
  border: none;
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  margin: 5px 0;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Styles for filename wrapping */
.filename-container {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

/* Mobile styles */
@media (max-width: 600px) {
  .container {
    padding: 15px;
  }

  h1 {
    font-size: 1.5em;
  }

  p {
    font-size: 14px;
  }

  input[type="text"],
  select,
  button,
  input[type="submit"] {
    width: calc(100% - 16px);
    padding: 8px;
    margin: 8px;
  }

  .toggle-button button {
    padding: 8px 16px;
  }
}

/* ------------ coffe.html ------------ */

.donate-methods {
  margin: 20px 0;
  text-align: left;
}

.donate-methods ul {
  list-style-type: none;
  padding: 0;
}

.donate-methods li {
  margin: 10px 0;
}

.back-to-home {
  margin-top: 20px;
}

.donate-link {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.donate-link:hover {
  background-color: #0056b3;
}

/* --- Добавляем стили для хедера --- */
.app-header {
  background-color: #ffffff;
  padding: 5px;
  position: sticky;
  top: 0;
  z-index: 100;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Полоска тени снизу */
  display: flex;
  justify-content: center;
}

.header-nav {
  display: flex;
  gap: 30px;
}

.header-nav a {
  color: #333;
  text-decoration: none;
  font-size: 18px;
  padding: 10px 15px;
  transition: all 0.3s ease;
}

/* Анимация при наведении */
.header-nav a:hover {
  color: #007bff;
}

.inactive-link {
  color: gray;
  cursor: not-allowed;
}

/* Мобильные стили для хедера */
@media (max-width: 768px) {
  .header-nav {
    flex-direction: column;
    gap: 15px;
  }

  .header-nav a {
    padding: 8px 10px;
    font-size: 16px;
  }
}
